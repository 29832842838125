/* eslint-disable */
import axios from 'axios';
import jwtDecode from 'jwt-decode';

export default {
  toggleDrawer({ commit }) {
    commit('toggleDrawer');
  },
  updateFilters ({ commit }, dashboardFilters) {
    commit('UPDATE_FILTERS', dashboardFilters);
  },
  UpdateStage ({ commit }, stagedata) {
    commit('UPDATE_STAGE', stagedata);
  },
  updateSettingsTab ({ commit }, activeTab) {
    commit('UPDATE_SETTINGS_TAB', activeTab);
  },
  updateStages ({ commit }, stagesData) {
    commit('UPDATE_STAGES', stagesData);
  },
  updateSnackbar ({ commit }, snackbarData) {
    commit('UPDATE_SNACKBAR', snackbarData);
  },
  createTempSession ({ commit }, userData) {
    commit('CREATE_TEMP_SESSION', userData);
  },
  companySettings ({ commit }, settings) {
    commit('COMPANY_SETTINGS', settings);
  },
  updateSortBy ({ commit }, settings) {
    commit('UPDATE_SORT_BY', settings);
  },
  createSession ({ commit }, userData) {
    localStorage.setItem('CHATBOT_USER', JSON.stringify(userData));
    commit('CREATE_SESSION', userData);
  },
  updateCompany ({ commit }, activeCompany) {
    commit('UPDATE_COMPANY', activeCompany);
  },
  updateCompanySettings ({ commit }, activeCompanySettings) {
    commit('UPDATE_COMPANY_SETTINGS', activeCompanySettings);
  },
  updateCompanyPlan ({ commit }, activeCompanyPlan) {
    commit('UPDATE_COMPANY_PLAN', activeCompanyPlan);
  },
  updatePermission ({ commit }, permission) {
    commit('UPDATE_PERMISSION', permission);
  },
  deleteSession ({ commit, state }, type) {
    commit('DELETE_SESSION');
    localStorage.removeItem('CHATBOT_USER');
  },
  updateSessionKey ({ commit }, key, keyData) {
    commit('UPDATE_SESSION_KEY', key, keyData);
  },
  refreshToken ({ commit }) {
    if (this.state.isApiBlocked) {
      console.warn('API calls are blocked due to a 400 error.');
      return;
    }
    const userData = this.state.user;
    axios.post(`${process.env.VUE_APP_API_URL_FB}refreshToken`, {
      token: this.state.user.access_token
    }).then((response) => {
      if (response.data) {
        commit('UPDATE_SESSION_KEY', response.data.token);
        const token = response.data.token;
        userData.access_token = token;
        localStorage.setItem('CHATBOT_USER', JSON.stringify(userData));
        commit('CREATE_SESSION', userData);
      }
    }).catch((error) => {
      if (error.response && error.response.status === 400) {
        this.state.isApiBlocked = true; // Block all further API calls
        this.dispatch('deleteSession', 'logout');
      }
    });
  },
  sessionSync ({ commit }) {
    delete axios.defaults.headers.common.Authorization;
    axios.post(`${process.env.VUE_APP_API_URL_FB}session-sync`, {
      email: this.state.user.email,
      access_token: this.state.user.access_token
    }).then((response) => {
      if (response) {
        localStorage.removeItem('CHATBOT_USER');
        commit('DELETE_SESSION');
      }
    });
  },
  inspectToken ({ commit }) {
    let token = '';
    if (this.state.user) {
      token = this.state.user.access_token;
    }
    if (token) {
      const decoded = jwtDecode(token);
      const exp = decoded.exp;
      const origIat = decoded.orig_iat;
      const timeBeforeRefresh = 7200;
      const maxLifespan = 604800 - timeBeforeRefresh;
      if (exp - (Date.now() / 1000) < timeBeforeRefresh && (Date.now() / 1000) - origIat < maxLifespan) {
        this.dispatch('refreshToken');
      }
    }
  },
  autoLogout ({ commit, state }) {
    commit('SET_LOGOUT_TRUE', state);
  },
  toggleSidebar ({ commit }) {
    commit('TOGGLE_SIDEBAR');
  },
  teamList ({ commit }, teamData) {
    commit('TEAM_LIST', teamData);
  },
  updateTeam ({ commit }, teamData) {
    commit('UPDATE_TEAM', teamData);
  },

  templatesList ({ commit }, list) {
    commit('TEMPLATE_LIST', list);
  },

  templateCategoryOptions ({ commit }, options) {
    commit('TEMPLATE_CATEGORY_OPTIONS', options);
  },

  activeTemplate ({ commit }, template) {
    commit('ACTIVE_TEMPLATE', template);
  },
  updateEmail ({ commit }, template) {
    commit('EMAIL_TEMPLATE', template);
  },

  updateActiveTemplateSubject ({ commit }, data) {
    commit('UPDATE_ACTIVE_TEMPLATE_SUBJECT', data);
  },

  updateActiveTemplateBody ({ commit }, data) {
    commit('UPDATE_ACTIVE_TEMPLATE_BODY', data);
  },

  updateActiveTemplateActionButton ({ commit }, data) {
    commit('UPDATE_ACTIVE_TEMPLATE_ACTION_BUTTON', data);
  },
  updateEmployeeFilters ({ commit }, data) {
    commit('UPDATE_EMPLOYEE_FILTERS', data);
  },
  singleEmpView ({ commit }, data) {
    commit('SINGLE_EMP_VIEW', data);
  },
  activeEmployee ({ commit }, data) {
    commit('ACTIVE_EMPLOYEE', data);
  },
  employeesList ({ commit }, data) {
    commit('EMPLOYEES_LIST', data);
  },
  loadingEmployeesList ({ commit }, data) {
    commit('LOADING_EMPLOYEES_LIST', data);
  },
  updateDashboardFilters ({ commit }, data) {
    commit('UPDATE_DASHBOARD_FILTERS', data);
  },
  updateReportFilters ({ commit }, data) {
    commit('UPDATE_REPORT_FILTERS', data);
  },
  updateReportData ({ commit }, data) {
    commit('UPDATE_REPORT_DATA', data);
  },
  updateQuestionFilter ({ commit }, data) {
    commit('UPDATE_QUESTION_FILTER', data);
  },
  updateDriversFilters ({ commit }, data) {
    commit('UPDATE_DRIVERS_FILTERS', data);
  },
  userWidth ({ commit }, data) {
    commit('USER_WIDTH', data);
  },
  updateBulkActions ({ commit }, data) {
    commit('UPDATE_BULK_ACTIONS', data);
  },
  sessionEmployeesCount ({ commit }, data) {
    commit('SESSION_EMPLOYEES_COUNT', data);
  },
  selectedEmployeesCount ({ commit }, data) {
    commit('SELECTED_EMPLOYEES_COUNT', data);
  },
  sessionEmployees ({ commit }, data) {
    commit('SESSION_EMPLOYEES', data);
  },
  sessionEmployeesSelected ({ commit }, data) {
    commit('SESSION_EMPLOYEES_SELECTED', data);
  },
  nonSessionEmployeesSelected ({ commit }, data) {
    commit('NON_SESSION_EMPLOYEES_SELECTED', data);
  },
  selectedEmployees ({ commit }, data) {
    commit('SELECTED_EMPLOYEES', data);
  },
  updatePageOffset ({ commit }, data) {
    commit('UPDATE_PAGE_OFFSET', data);
  },
  updateSearchString ({ commit }, data) {
    commit('UPDATE_SEARCH_STRING', data);
  },
  isDashboardPermission ({ commit }, data) {
    commit('IS_DASHBOARD_PERMMISSION', data);
  },
  driverData ({ commit }, data) {
    commit('DRIVER_DATA', data);
  },
  driversResponses ({ commit }, data) {
    commit('DRIVER_RESPONSES', data);
  },
  driversHeatmap ({ commit }, data) {
    commit('DRIVERS_HEATMAP', data);
  },
  driversDemographic ({ commit }, data) {
    commit('DRIVERS_DEMOGRAPHIC', data);
  },
  heatmapLoading ({ commit }, data) {
    commit('HEATMAP_LOADING', data);
  },
  adhocData ({ commit }, data) {
    commit('ADHOC_DATA', data);
  },
  interactionDrivers ({ commit }, data) {
    commit('INTERACTION_DRIVER', data);
  }
};
